<template>
  <div style="flex: 1">
    <div v-show="!isAfterAdd">
      <div class="tip1">
        如果帖子中存在泄露隐私的内容或其它不当内容，可以先局部调整修改内容后再审核。
      </div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <div style="display: flex">
          <div style="flex: 1;">
            <a-form-model-item
                label="帖子类型"
                prop="type"
                v-bind="formItemLayout"
            >
              <a-radio-group v-model="form.type">
                <a-radio :value="1">普通帖子</a-radio>
                <a-radio :value="11">面经帖子</a-radio>
                <a-radio :value="6">社区帖子</a-radio>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item
                label="内容分类"
                prop="cateId"
                v-bind="formItemLayout"
            >
              <a-select :options="cateList" v-model="form.cateId" v-decorator="['cateId']" @change="cateChanged"
                        placeholder="内容分类"
                        style="width:200px"></a-select>
            </a-form-model-item>

            <a-form-model-item
                ref="title"
                label="城市"
                prop="title"
                v-bind="formItemLayout">
              <a-input
                  v-model="form.city"
                  allow-clear
                  placeholder="输入城市"
              />
            </a-form-model-item>
            <a-form-model-item
                ref="title"
                label="公司名称"
                prop="title"
                v-bind="formItemLayout">
              <div style="display: flex">
                <a-input
                    v-model="form.company"
                    allow-clear
                    placeholder="公司名称"
                />
                <a-button type="primary" @click="selectCompany">选择公司</a-button>
              </div>
            </a-form-model-item>
            <a-form-model-item
                ref="title"
                label="帖子标题"
                prop="title"
                v-bind="formItemLayout">
              <a-input
                  v-model="form.title"
                  allow-clear
                  placeholder="输入标题"
              />
            </a-form-model-item>
            <a-form-model-item label="内容" v-bind="formItemLayout" prop="content">
              <tiny-mce-editor v-if="initied" ref="editor" v-model="form.content" style="width: 100%"></tiny-mce-editor>
                          <a-textarea v-model="form.content" :rows="3" allClear/>
            </a-form-model-item>

            <a-form-model-item
                ref="title"
                label="发帖用户"
                prop="title"
                v-bind="formItemLayout">
              <div style="display: flex;align-items: center">
                <a-input
                    v-model="form.username"
                    disabled
                    :aria-readonly="true"
                    placeholder="选择发帖用户"
                    style="width:300px"
                />
                <a-button type="primary" @click="selectUser">选择用户</a-button>
                <a-checkbox v-model="form.randomUser" style="margin-left:30px" @change="form.username=''"><b>随机选择用户</b>
                </a-checkbox>
              </div>
            </a-form-model-item>
            <a-form-model-item
                label="来源类型"
                prop="sourceType"
                v-bind="formItemLayout"
            >
              <a-radio-group v-model="form.sourceType">
                <a-radio :value="1">用户发帖</a-radio>
                <a-radio :value="2">爬虫数据</a-radio>
                <a-radio :value="3">admin发帖</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item
                label="发帖时间"
                prop="createTime"
                v-bind="formItemLayout"
            >
              <a-date-picker show-time placeholder="选择日期时间" @ok="onCreateTimeOk"/>
            </a-form-model-item>
          </div>
          <div style="flex:1;">
            <a-form-model-item ref="doorHeader" label="上传图片" v-bind="formItemLayout">
              <div class="clearfix">
                <a-upload
                    name="file"
                    list-type="picture-card"
                    class="avatar-uploader"
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                    withCredentials
                    :multiple="true"
                    :data="{ bucketType: 'public', uploadType: 'image' }"
                    :headers="headers"
                    :file-list="imageList"
                    action="admin/upload/uploadImage"
                    :before-upload="beforeUpload"
                    @preview="handlePreview"
                    @change="(info) => handleImageChange(info)"
                >
                  <div v-if="imageList.length < 9">
                    <a-icon type="plus"/>
                    <div class="ant-upload-text">上传</div>
                  </div>
                </a-upload>
                <!--  -->
                <!--          <a-modal-->
                <!--              v-drag-modal-->
                <!--              title="图片预览"-->
                <!--              :visible="previewVisible"-->
                <!--              :footer="null"-->
                <!--              @cancel="previewVisible = false"-->
                <!--          >-->
                <!--            <img alt="example" style="width: 100%" :src="previewImage"/>-->
                <!--          </a-modal>-->
              </div>
              <div class="tip1" style="margin:-20px 0 0 0">鼠标移动到图片上，再点击图片中的预览按钮，可以直接在线打马赛克。</div>
            </a-form-model-item>
            <div v-if="form.type==11">
              <a-form-model-item
                  ref="title"
                  label="面试职位"
                  prop="otherJson.title"
                  v-bind="formItemLayout">
                <a-input
                    v-model="form.otherJson.title"
                    allow-clear
                    placeholder="面试职位"
                />
              </a-form-model-item>
              <a-form-model-item
                  ref="title"
                  label="面试难度"
                  prop="otherJson.title"
                  v-bind="formItemLayout">
                <a-radio-group v-model="form.otherJson.difficult">
                  <a-radio value="容易">容易</a-radio>
                  <a-radio value="一般">一般</a-radio>
                  <a-radio value="有难度">有难度</a-radio>
                  <a-radio value="困难">困难</a-radio>
                  <a-radio value="巨难">巨难</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item
                  ref="title"
                  label="薪资待遇"
                  prop="otherJson.salary"
                  v-bind="formItemLayout">
                <a-input
                    v-model="form.otherJson.salary"
                    allow-clear
                    placeholder="薪资待遇"
                />
              </a-form-model-item>
              <a-form-model-item
                  ref="title"
                  label="五险一金"
                  prop="otherJson.salary"
                  v-bind="formItemLayout">
                <a-input
                    v-model="form.otherJson.insurance"
                    allow-clear
                    placeholder="五险一金缴纳情况"
                />
              </a-form-model-item>
              <a-form-model-item
                  ref="title"
                  label="休息情况"
                  prop="otherJson.rest"
                  v-bind="formItemLayout">
                <a-input
                    v-model="form.otherJson.rest"
                    allow-clear
                    placeholder="单休/双休/大小周/月休4天/其他"
                />
              </a-form-model-item>
              <a-form-model-item
                  ref="title"
                  label="面试结果"
                  prop="otherJson.title"
                  v-bind="formItemLayout">
                <a-radio-group v-model="form.otherJson.result">
                  <a-radio value="未通过">未通过</a-radio>
                  <a-radio value="无结果">无结果</a-radio>
                  <a-radio value="已通过">已通过</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </div>
          </div>
        </div>

      </a-form-model>
      <div style="display: flex;">
        <a-button v-if="action!='view'" type="primary" @click="handleOk" :loading="confirmLoading"
                  style="margin-left: 40px;">立即保存
        </a-button>
      </div>
    </div>
    <image-preview-modal ref="previewModal" @updateSuccess="updateSuccess"/>
    <select-company-modal ref="companyModal" @onSelected="onCompanySelected"/>
    <select-poster-modal ref="userModal" @onSelected="onUserSelected"/>
    <a-result
        v-show="isAfterAdd"
        status="success"
        title="帖子新增成功!"
        :sub-title="'新增帖子ID: '+form.id"
    >
      <template #extra>
        <a-button key="list" type="success" @click="goList">
          列表查看
        </a-button>
        <a-button key="add" type="primary" @click="addNew">
          立即新增
        </a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
import TinyMceEditor from "@/components/Editor/TinyMceEditor";
import ImagePreviewModal from "../postlist/ImagePreviewModal";
import {DICT} from "@/utils/dict.js";
import SelectCompanyModal from "./SelectCompanyModal";
import SelectPosterModal from "./SelectPosterModal";

import {getBase64OfPreview} from '@/utils/common.js';

export default {
  name: "AddNewPost",
  components: {SelectPosterModal, SelectCompanyModal, TinyMceEditor, ImagePreviewModal},
  data() {
    return {
      action: 'new',
      dict: DICT,
      formItemLayout: {
        labelCol: {span: 3},
        wrapperCol: {span: 20},
      },
      title: "",
      confirmLoading: false,
      record: {},
      initied: false,
      form: {
        type: 1,
        cateId: '',
        company: '',
        title: '',
        sourceType: 3,
        otherJson: {},
        randomUser: false
      },
      imageList: [],
      orgCateList: [],
      previewImage: "",
      isAfterAdd: false,
      rules: {
        type: [
          {
            required: true,
            message: "请选择帖子类型",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
      },
      counter: 0,
    };
  },
  computed: {
    headers() {
      return {
        Authentication: this.$store.state.account.token,
      };
    },
    otherJson() {
      return JSON.parse(this.form.otherJson || "{}");
    },
    cateList() {
      let cateType = 1;//类型（1--圈子分类，2--手册分类，3--评测分类,4--省钱宝分类）
      if (this.form.type == 14) {
        cateType = 2;
      }
      return this.orgCateList.filter(v => v.type === cateType).map(v => {
        return {
          value: v.cateId,
          label: v.cateName
        }
      })
    }
  },
  created() {
    this.$postJson('/category/cateList', {pageSize: 100}).then(res => {
      this.orgCateList = ((res.data && res.data.records) || [])
    })
  },
  mounted() {
    this.showModal({}, 'new')
    this.$nextTick(() => {
      this.initied = true
    })
  },
  methods: {
    addNew() {
      this.isAfterAdd = false;
      this.form = {
        type: 1,
        cateId: '',
        company: '',
        title: '',
        sourceType: 3,
        otherJson: {}
      };
      this.imageList = []
      this.loading = false;
      this.confirmLoading = false
      this.initied = true;
    },
    goList() {
      setTimeout(this.addNew, 500)
      this.$router.push('/post/postlist');
    },
    handleImageChange(info) {
      this.imageList = info.fileList || [];
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      this.loading = false;
    },
    cateChanged(cateId) {
      let findOne = this.cateList.find(v => v.value == cateId);
      if (findOne) {
        this.form.category = findOne.label
      }
    },
    onCreateTimeOk(value){
      console.log(value)
      this.form.createTime = value.format("YYYY-MM-DD HH:mm:ss")
    },
    beforeUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("只能支持JPEG/PNG/GIF图片!");
      }
      const isLt2M = file.size / 1024 / 1024 <= 3;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过3MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    async handlePreview(file) {
      if (!(file.url || (file.response && file.response.data) || file.preview || file.thumbUrl)) {
        file.preview = await getBase64OfPreview(file.originFileObj);
      }
      this.previewImage = file.url || (file.response && file.response.data) || file.preview || file.thumbUrl;
      this.previewVisible = true;
      this.$refs.previewModal.preview(this.previewImage, this.record, this.imageList)
    },
    async showModal(record, action) {
      this.action = action || 'edit';
      this.record = record || {}
      let imageList = [];
      if (this.action == 'edit' || this.action == 'view') {
        let {data} = await this.$get('post/info/' + (typeof record === 'object' ? record.id : record))
        this.form = {
          ...data,
        };
        //record is just post id
        this.title = "帖子ID：" + data.id;
        if (data.media) {
          imageList = JSON.parse(data.media) || [];
        }
        const that = this;

        let array = [];
        imageList.forEach((v) => {
          if (!v) return;
          let path = that.$helper.getFullImgPath(v);
          array.push({
            type: "image/png",
            name: v,
            uid: v,
            status: "done",
            thumbUrl: path,
            response: {data: path, code: 0},
          });
        });
        this.imageList = array;
        if (typeof record == 'object') {
          record.imageList = this.imageList;
        }
      } else {
        this.imageList = []
      }
    },
    selectCompany() {
      this.$refs.companyModal.showModal();
    },
    onCompanySelected(item) {
      this.form.company = item.company;
      this.form.companyId = item.id;
      this.$forceUpdate()
    },
    selectUser(mode) {
      this.$refs.userModal.showModal();
    },
    onUserSelected(item) {
      this.form.uid = item.uid;
      this.form.username = item.username;
      this.form.randomUser = false;
      this.$forceUpdate()
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          if (this.form.content.indexOf("data:image/") >= 0) {
            this.$message.warn("图片请选择上传方式，不要直接剪切后粘贴图片！");
            return
          }
          this.confirmLoading = true;
          let params = {
            ...this.form
          }
          params.media = this.imageList.map((item) => {
            return item.response.data;
          });
          if (params.id > 0) {
            params.media = JSON.stringify(params.media);
          }

          this.$postJson(params.id > 0 ? "post/updatePost" : "post/addPost", params).then((res) => {
            this.confirmLoading = false;
            this.$message.success("修改成功");
            this.isAfterAdd = true;
            this.initied = false;
            this.$emit("success");
          }).catch(()=>{
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
    auditPost() {
      this.$refs.postAuditModal.showModal(this.record);
    },
    auditSuccess(passFlag) {
      if (passFlag == 1) {
        this.updateSuccess();
      }
    },

    updateSuccess() { //图片修改成功
      this.confirmLoading = false;
      this.$emit("success");
    },
    closeDialog(newImgPath) {
      this.imgEditShow = false
      this.editMode = false;
      if (newImgPath) {
        this.$postJson('post/updateOneImg', {
          img: this.previewImage,
          newImg: newImgPath,
          postId: this.record.id
        }).then(res => {
          this.previewImage = newImgPath
          this.$message.success('图片编辑更新成功');
          this.confirmLoading = false;
          this.$emit("success");
        })

        //修改数据
      }
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}

.tip1 {
  color: #FF9900;
  margin: 0px 80px 20px;
}

/deep/ .anticon-delete {
  margin-left: 20px !important;
  color: red !important;
}
</style>
